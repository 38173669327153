export default {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "YOUR_API_GATEWAY_URL"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_h7lA51VCc",
    APP_CLIENT_ID: "6nvoutuapd5860db4i7snqi59q",
    IDENTITY_POOL_ID: "eu-west-1:bf813bd9-defe-49fb-9b80-5b5792283360"
  }
}
