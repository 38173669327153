import React, { Component } from "react";
import "./Home.css";
import pasytlogo from '../img/pasytlogo.png'

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
            <img src={pasytlogo} alt="pasyt"/>
        </div>
      </div>
    );
  }
}